import PropTypes from 'prop-types'
import React from "react";
import Title from "../../components/Title";
import { withStyles } from '@material-ui/core/styles';
import BitmojiWave from "../../images/home/bitmoji_wave.png";
import Typography from "@material-ui/core/Typography";

class Home extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    }
  };

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth});
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateWindowWidth);
    document.title = "Sydney Blackburn | Home";
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  };

  render () {
    const { classes } = this.props;
    const { windowWidth } = this.state;

    return (
      <div
        className={
          windowWidth <= 450
          ? classes.smallContainer
          : classes.container
        }
        style={{"minHeight": window.innerHeight}}
      >
        <Title title="Home" />
        <div className={classes.row}>
          <div
            className={
              windowWidth <= 450
                ? classes.smallColumnOne
                : classes.columnOne
            }
          >
            <img
              src={BitmojiWave}
              alt={"Sydney Blackburn Bitmoji"}
              title={"Sydney Blackburn"}
              className={
                windowWidth <= 450
                  ? classes.smallImg
                  : classes.img
              }
            />
          </div>
          <div
            className={
              windowWidth <= 450
                ? classes.smallColumnTwo
                : classes.columnTwo
            }
          >
            <Typography
              variant="h1"
              component="h1"
              className={
                windowWidth <= 450
                  ? classes.smallTitle
                  : classes.title
              }
            >
              Software Developer
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    margin: "75px 50px 20px 50px",
    height: "100%"
  },
  smallContainer: {
    margin: "75px 10px 20px 10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center"
  },
  smallColumnOne: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  columnOne: {
    flexBasis: "39%",
    marginRight: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  smallColumnTwo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  columnTwo: {
    display: "flex",
    flexBasis: "59%",
    marginLeft: "5px",
    alignItems: "center",
    justifyContent: "center"
  },
  img: {
    marginTop: "10px",
    display: "block",
    width: "100%",
    height: "auto"
  },
  smallImg: {
    height: "300px"
  },
  title: {
    fontSize: "50pt",
    textAlign: "center"
  },
  smallTitle: {
    fontSize: "35pt",
    textAlign: "center"
  }
});

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);