import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Inputs from "../Inputs/";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: ""
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    console.log("submit", event);
  };

  formatMessage = (message) => {
    return message.split('\n').join('%0D%0A')
      + "%0D%0A%0D%0A From: " + this.state.name
      + " <" + this.state.email + ">";
  };

  render() {
    const myEmail = "sydneyblackburn10@gmail.com";
    const { subject, message } = this.state;
    const { classes } = this.props;
    return (
      <form
        className={classes.contactForm}
        onSubmit={this.handleSubmit.bind(this)}
      >
        <Inputs
          label="Name"
          callback={(data) => this.setState({name: data})}
        />
        <Inputs
          label="Email"
          callback={(data) => this.setState({email: data})}
        />
        <Inputs
          label="Subject"
          callback={(data) => this.setState({subject: data})}
        />
        <Inputs
          label="Message"
          callback={(data) => this.setState({message: data})}
          multiline={true}
          rows={10}
        />
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="outlined"
            href={`mailto:${myEmail}?subject=${subject}&body=${this.formatMessage(message)}`}
          >
            Send
          </Button>
        </div>
      </form>
    );
  }
}

const styles = (theme) => ({
  contactForm: {
    display: "flex",
    flexDirection: "column"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  }
});

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ContactForm);