import { createMuiTheme } from "@material-ui/core/styles";
import {
  colorBackground,
  colorBlack,
  colorBlueAccent as primary,
  colorRed,
  colorWhite,
  colorGray
} from "./colors";

const spacing = 5;
const theme = createMuiTheme ({
  spacing: spacing,
  palette: {
    primary: {
      main: primary
    },
    black: {
      main: colorBlack
    },
    white: {
      main: colorWhite
    },
    error: {
      main: colorRed
    },
    background: {
      main: colorBackground
    },
    gray: {
      main: colorGray
    }
  },
  MuiTypography: {
    variantMapping: {
      h1: 'h2',
      h2: 'h2',
      h3: 'h2',
      h4: 'h2',
      h5: 'h2',
      h6: 'h2',
      subtitle1: 'h2',
      subtitle2: 'h2',
      body1: 'span',
      body2: 'span',
      body3: 'span'
    }
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: "30pt",
        fontWeight: "bold",
        color: colorWhite,
        margin: "10px 0",
        textAlign: "center",
        opacity: ".9"
      },
      h2: {
        fontSize: "24pt",
        fontWeight: "bold",
        color: colorWhite,
        margin: "10px 0",
        opacity: ".9"
      },
      h3: {
        fontSize: "20pt",
        fontWeight: "bold",
        color: colorWhite,
        margin: "10px 0",
        opacity: ".9"
      },
      h4: {
        fontSize: "18pt",
        fontWeight: "bold",
        color: colorWhite,
        margin: "10px 0",
        opacity: ".9"
      },
      body1: {
        fontSize: "14pt",
        color: colorWhite,
        margin: "10px 0",
        opacity: ".9",
        lineHeight: "30px"
      },
      body2: {
        fontSize: "12pt",
        color: colorWhite,
        opacity: ".9",
        margin: "10px 0",
        lineHeight: "30px"
      },
      subtitle1: {
        fontSize: "9pt",
        color: colorWhite,
        opacity: ".9",
        margin: "10px 0"
      },
    },
    MuiInput: {
      root: {}
    },
    MuiInputBase: {
      root: {
        color: colorWhite
      }
    },
    MuiFormLabel: {
      root: {
        color: colorWhite,
        "&$focused": {
          color: primary
        }
      }
    },
    MuiFormControl: {
      root: {
        margin: "5px 0px"
      }
    },
    MuiButton: {
      root: {
        color: colorWhite,
        width: "25%",
        marginTop: spacing,
        "&:hover": {
          color: "rgba(240, 240, 240, 1)" // colorWhite so button label stays solid
        },
        "&:active": {
          color: primary
        }
      },
      outlined: {
        border: "1px solid rgba(240, 240, 240, .5)", // colorWhite so border is transparent and label isn't
        "&:hover": {
          border: "1px solid " + colorWhite,
          opacity: 1
        },
        "&:active": {
          border: "1px solid " + primary,
        }
      }
    },
    MuiButtonBase: {
      root: {
        "&:hover": {
          borderColor: colorBlack
        }
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: colorWhite,
          opacity: 1
        }
      },
      notchedOutline: {
        borderColor: colorWhite,
        opacity: .5,
        borderWidth: "1px"
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: colorBackground
      },
      elevation1: {
        boxShadow:
          "0px 5px 5px -3px " + colorBlack +
          ", 0px 8px 10px 1px " + colorBlack +
          ", 0px 3px 14px 2px " + colorBlack
      },
      elevation8: {
        boxShadow:
          "0px 5px 5px -3px " + colorBlack +
          ", 0px 8px 10px 1px " + colorBlack +
          ", 0px 3px 14px 2px " + colorBlack
      }
    },
    MuiCard: {
      root: {
        margin: "10px 0px",
        backgroundColor: colorGray
      },
    },
    MuiCardMedia: {
      root: {}
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: 0
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: colorBackground
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: colorBackground,
        display: "block"
      }
    },
    MuiSvgIcon: {
      root: {
        color: primary
      }
    }
  },
});

export default theme;

// import { withStyles } from "@material-ui/core/styles";
// const styles = theme => ({
//   label: {
//     // paddingLeft: "100px"
//   }
// });
// const { classes } = this.props;
// <FormControl className={classes.label}>
// export default withStyles(styles)(ContactForm);