import PropTypes from 'prop-types'
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const SingleLineGridList = (props) => {
  const { classes, tileData } = props;

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={2.5}>
        {tileData.map((tile, index) => (
          <GridListTile
            className={classes.gridListTile}
            key={index}
          >
            {tile}
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.main,
    marginBottom: "15px"
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: "0px!important"
  },
  gridListTile: {
    width: "auto!important",
    height: "auto!important",
    marginBottom: "5px!important"
  }
});

SingleLineGridList.propTypes = {
  classes: PropTypes.object.isRequired,
  tileData: PropTypes.array.isRequired
};

export default withStyles(styles)(SingleLineGridList);