import PropTypes from 'prop-types'
import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinkedIn from '../../images/contact/LI-In-Bug.png';
import ImageLink from "../ImageLink/";
import BitmojiSurprise from "../../images/footer/bitmoji_surprise.png";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    }
  }

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth});
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  };

  render () {
    const {classes} = this.props;
    const { windowWidth} = this.state;
    const lastModified = document.lastModified;

    return (
      <div
        className={
          windowWidth <= 450
            ? classes.smallFooter
            : classes.footer
        }
      >
        <div className={classes.row}>
          <div
            className={
              windowWidth <= 450
                ? classes.smallColumn
                : classes.column
            }
          >
            <div>
              <Typography variant="subtitle1" component="p">
                {`Email: `}
                <a href={`mailto:sydneyblackburn10@gmail.com`}>
                  sydneyblackburn10@gmail.com
                </a>
              </Typography>
              <div className={classes.imagesContainer}>
                <ImageLink
                  link="https://www.linkedin.com/in/sydneyraeblackburn/"
                  src={LinkedIn}
                  alt="LinkedIn Logo"
                  title="LinkedIn"
                  height="25px"
                  noMargin={true}
                />
              </div>
            </div>
          </div>
          <div
            className={
              windowWidth <= 450
                ? classes.smallColumn
                : classes.column
            }
          >
            <div>
              <Typography variant="subtitle1" component="p">
                {`Last Updated: ${lastModified}`}
              </Typography>
              <Typography variant="subtitle1" component="p">
                © 2019 Sydney R. Blackburn
              </Typography>
            </div>
          </div>
          <div className={classes.column}>
            <div>
              <img
                src={BitmojiSurprise}
                title="Sydney Blackburn"
                alt="Sydney Blackburn Bitmoji"
                className={classes.bitmoji}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  column: {
    display: "flex",
    flexBasis: "32%",
    marginRight: "5px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexWrap: "wrap"
  },
  smallColumn: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  footer: {
    padding: "0px 100px",
    backgroundColor: theme.palette.black.main
  },
  smallFooter: {
    padding: "0px 50px",
    backgroundColor: theme.palette.black.main
  },
  imagesContainer: {
    display: "flex",
    justifyContent: "center",
  },
  img: {
    height: "25px",
    width: "auto"
  },
  bitmoji: {
    height: "150px",
    width: "auto"
  }
});

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);