import React from "react";
import Title from "../../components/Title";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import ExpansionPanels from "../../components/ExpansionPanels";

class Resume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    }
  };

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth});
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateWindowWidth);
    document.title = "Sydney Blackburn | Resume";
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  };

  render () {
    const { classes } = this.props;
    const { windowWidth } = this.state;

    return (
      <div
        className={
          windowWidth <= 450
            ? classes.smallContainer
            : classes.container
        }
        style={{"minHeight": window.innerHeight}}
      >
        <Title title="Resume" />
        <div className={classes.expansionPanel}>
          <ExpansionPanels
            title={"Experience"}
            blocks={[
              {
                title: ["Site Reliability Engineer, Circadence"],
                date: ["May 2020 - Present"],
                info: (
                  <React.Fragment>
                  Providing resilient and scalable solutions for a cyber security education platform to maximize availability, reliability, performance, and security by participating in 12/7 on-call rotations, troubleshooting VM and container management systems, debugging cluter failures, and implementing monitoring solutions with Prometheus and Grafana. Utilizing advanced technologies including Kubernetes, Istio, Mongo, RabbitMQ, and Azure Portal while also maintaining Go microservices.
                  <ul className={classes.ul}>
                    <li>Created a postmortem template and process of maintaining ducomentation that was adopted by the company</li>
                    <li>Integrated Argo Rollouts with Azure CD pipelines for blue/green and canary deployments</li>
                    <li>Set up developer environments utilizing MSSQL and Redis for easier and more productive development</li>
                  </ul>
                  </React.Fragment>
                )
              },
              {
                title: [
                  "Software Engineer, Circadence",
                  "Frontend Engineering Intern, Circadence"
                ],
                date: [
                  "December 2019 - May 2020",
                  "June 2019 - December 2019"
                ],
                info: (
                  <React.Fragment>
                  Contributed to the design and development of a complex, dockerized ReactJS web application. Built dynamic components and managed application state through open-source frameworks and libraries. Utilized technologies including Javascript (ES6), Redux, Material-UI, and Jest.
                  <ul className={classes.ul}>
                    <li>Created automatic MongoDB backups utilizing Kubernetes Cronjobs to circumvent the issue of data loss</li>
                    <li>Assisted in the creation of migration scripts to preserve customer data between production data changes</li>
                    <li>Increased total frontend unit test coverage by 10% with Jest and Enzyme</li>
                  </ul>
                  </React.Fragment>
                )
              }
            ]}
          />
          <ExpansionPanels
            title={"Technical Skills"}
            blocks={[
              {
                title: ["Languages"],
                info: "Go, Javascript (ES6), HTML5, CSS, Python, Bash"
              },
              {
                title: ["CI/CD"],
                info: "Azure pipelines, Argo Rollouts, blue/green and canary deployments"
              },
              {
                title: ["Monitoring"],
                info: "Azure Portal, App Insights, Grafana, Prometheus, Kiali"
              },
              {
                title: ["Libraries"],
                info: "React, Redux"
              },
              {
                title: ["Frameworks"],
                info: "Jest, Enzyme, React Native, Material-UI"
              },
              {
                title: ["IDEs"],
                info: "GoLand, VS Code, Webstorm"
              },
              {
                title: ["Databases"],
                info: "Mongo, Redis, MSSQL"
              },
              {
                title: ["Microservice Architecture"],
                info: "Helm, Istio, Kubernetes, Docker"
              },
              {
                title: ["RPC"],
                info: "Bloom RPC, Postman, gRPC, REST, Swagger"
              },
              {
                title: ["Management Tools"],
                info: "RabbitMQ, B2C Authentication, Tox"
              },
              {
                title: ["Data-Serialization"],
                info: "JSON, YAML, XML, Protocol Buffers"
              },
              {
                title: ["Operating Systems"],
                info: "Unix, Linux"
              }
            ]}
          />
          <ExpansionPanels
            title={"Education"}
            blocks={[
              {
                title: ["Advanced Web and Mobile App Development Certificate from San Diego State University"],
                date: ["August 2018 - May 2019"],
                info: (
                  <ul className={classes.ul}>
                    <li>GPA: 4.0/4.0</li>
                    <div>
                      <li>Courses:</li>
                      {windowWidth <= 450
                        ? <div className={classes.row}>
                          <div>
                            <ul className={classes.ul}>
                              <li>Introduction to Web Application Development</li>
                              <li>Human Computer Interfaces</li>
                            </ul>
                          </div>
                          <div>
                            <ul className={classes.ul}>
                              <li>iOS Mobile Application Development</li>
                              <li>Android Mobile Application Development</li>
                            </ul>
                          </div>
                      </div>
                      : <div className={classes.row}>
                        <div className={classes.columnOne}>
                          <ul className={classes.ul}>
                            <li>Introduction to Web Application Development</li>
                            <li>Human Computer Interfaces</li>
                          </ul>
                        </div>
                        <div className={classes.columnTwo}>
                          <ul className={classes.ul}>
                            <li>iOS Mobile Application Development</li>
                            <li>Android Mobile Application Development</li>
                          </ul>
                        </div>
                      </div>
                      }
                    </div>
                  </ul>
                )
              },
              {
                title: ["Bachelor of Science in Computer Science from San Diego State University"],
                date: ["August 2013 - December 2017"],
                info: (
                  <ul className={classes.ul}>
                    <li>GPA: 3.49/4.0</li>
                    <div>
                      <li>Courses:</li>
                      {windowWidth <= 450
                        ? <div className={classes.row}>
                          <div>
                            <ul className={classes.ul}>
                              <li>Introduction to Computer Science</li>
                              <li>Machine Organization and Assembly Language</li>
                              <li>Programming Languages</li>
                              <li>Social, Legal, and Ethical Issues in Computing</li>
                              <li>Database Theory and Implementation</li>
                              <li>Algorithms and Their Analysis</li>
                              <li>Computational Linguistics</li>
                              <li>Calculus</li>
                              <li>Discrete Mathematics</li>
                              <li>Introduction to Numerical Analysis and Computing</li>
                            </ul>
                          </div>
                          <div>
                            <ul className={classes.ul}>
                              <li>Intermediate Computer Science</li>
                              <li>Data Structures</li>
                              <li>Computer Architecture</li>
                              <li>Unix System Administration</li>
                              <li>Systems Programming</li>
                              <li>Operating Systems</li>
                              <li>3D Game Programming</li>
                              <li>Calculus II</li>
                              <li>Introduction to Linear Algebra</li>
                            </ul>
                          </div>
                        </div>
                        : <div className={classes.row}>
                          <div className={classes.columnOne}>
                            <ul className={classes.ul}>
                              <li>Introduction to Computer Science</li>
                              <li>Machine Organization and Assembly Language</li>
                              <li>Programming Languages</li>
                              <li>Social, Legal, and Ethical Issues in Computing</li>
                              <li>Database Theory and Implementation</li>
                              <li>Algorithms and Their Analysis</li>
                              <li>Computational Linguistics</li>
                              <li>Calculus</li>
                              <li>Discrete Mathematics</li>
                              <li>Introduction to Numerical Analysis and Computing</li>
                            </ul>
                          </div>
                          <div className={classes.columnTwo}>
                            <ul className={classes.ul}>
                              <li>Intermediate Computer Science</li>
                              <li>Data Structures</li>
                              <li>Computer Architecture</li>
                              <li>Unix System Administration</li>
                              <li>Systems Programming</li>
                              <li>Operating Systems</li>
                              <li>3D Game Programming</li>
                              <li>Calculus II</li>
                              <li>Introduction to Linear Algebra</li>
                            </ul>
                          </div>
                        </div>
                      }
                    </div>
                  </ul>
                )
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    margin: "75px 50px 20px 50px",
    height: "100%"
  },
  smallContainer: {
    margin: "75px 10px 20px 10px",
  },
  expansionPanel: {
    paddingTop: "10px"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  columnOne: {
    flexBasis: "49%",
    marginRight: "5px"
  },
  columnTwo: {
    flexBasis: "49%",
    marginLeft: "5px"
  },
  ul: {
    marginLeft: "-20px"
  }
});

Resume.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Resume);