import React from "react";
import FormControl from '@material-ui/core/FormControl';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const Inputs = (props) => {
  const { multiline, rows, classes, label, callback } = props;

  const handleChange = (event) => {
    callback(event.target.value);
  };

  return (
    <FormControl className={multiline ? classes.multiline : classes.textField} component='div'>
      <TextField
        label={label}
        onChange={handleChange}
        fullWidth={true}
        variant="outlined"
        multiline={multiline || false}
        rows={rows || 1}
      />
    </FormControl>
  );
};

const styles = () => ({
  multiline: {
    width: "100%"
  },
  textField: {
    width: "100%"
  }
});

Inputs.propTypes = {
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
};

export default withStyles(styles)(Inputs);