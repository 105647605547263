import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const ImageLink = (props) => {
  const { link, src, alt, classes, title, height, noMargin } = props;

  return (
    <a
      href={link}
      target="_blank" rel="noopener noreferrer"
    >
      <img
        className={ noMargin ? classes.noMargin : classes.image}
        src={src}
        alt={alt}
        title={title}
        style={{ "height": height || "auto", "width": "auto"}}
      />
    </a>
  );
};

const styles = () => ({
  image: {
    marginRight: "25px",
    marginBottom: "25px"
  },
  noMargin: {
    marginRight: 0,
    marginBottom: 0
  }
});

ImageLink.propTypes = {
  alt: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  height: PropTypes.string,
  link: PropTypes.string.isRequired,
  noMargin: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(ImageLink);