import React from 'react';
import { render } from "react-dom";
import './css/index.css';
import App from "./App";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./theme/style";

const target = document.querySelector('#main');

render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  target
);
