import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Hamburger from "../Hamburger/";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    }
  };

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth});
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  };

  render () {
    const { classes } = this.props;
    const { windowWidth } = this.state;

    return (
      windowWidth  >= 970 ? (
        <Paper raised="true" className={classes.paper}>
          <nav className={classes.nav}>
            <div className={classes.columnOne}>
              <NavLink
                to={'/'}
                exact={true}
                activeClassName={classes.isActive}
                className={classes.navLink && classes.home}
              >
                SYDNEY
                <strong className={classes.strong}>
                  BLACKBURN
                </strong>
              </NavLink>
            </div>
            <div className={classes.columnTwo}>
              {/* <NavLink
                to={'/Blog'}
                activeClassName={classes.isActive}
                className={classes.navLink}
              >
                Blog
              </NavLink> */}
              {/* <NavLink
                to={'/Projects'}
                activeClassName={classes.isActive}
                className={classes.navLink}
              >
                Projects
              </NavLink> */}
              <NavLink
                to={'/Resume'}
                activeClassName={classes.isActive}
                className={classes.navLink}
              >
                Resume
              </NavLink>
              <NavLink
                to={'/Contact'}
                activeClassName={classes.isActive}
                className={classes.navLink}
              >
                Contact
              </NavLink>
            </div>
          </nav>
        </Paper>
      ) : (
        <Paper raised="true" className={classes.paper}>
          <nav className={classes.nav}>
            <div className={classes.columnOne}>
              <NavLink
                to={'/'}
                exact={true}
                activeClassName={classes.isActive}
                className={
                  windowWidth <= 450 ?
                    (classes.navLink && classes.homeSmall)
                  : (classes.navLink && classes.home)
                }
              >
                SYDNEY
                <strong className={classes.strong}>
                  BLACKBURN
                </strong>
              </NavLink>
            </div>
            <div className={classes.columnTwo}>
              <Hamburger />
            </div>
          </nav>
        </Paper>
      )
    );
  }
}

const styles = (theme) => ({
  nav: {
    margin: 0,
    height: "55px",
    overflow: "hidden",
    background: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  navLink: {
    textDecoration: "none",
    padding: "0px 25px",
    color: theme.palette.black.main,
    width: "auto",
    textAlign: "center",
    transition: "0.3s",
    height: "inherit",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover:not($active)": {
      color: theme.palette.white.main
    }
  },
  isActive: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.white.main
  },
  home: {
    padding: "0 25px",
    textDecoration: "none",
    fontSize: "35pt",
    fontWeight: "lighter",
    background: "none",
    color: theme.palette.white.main,
    "&:hover:not($active)": {
      color: theme.palette.white.main
    }
  },
  homeSmall: {
    padding: "0 10px",
    textDecoration: "none",
    fontSize: "16pt",
    fontWeight: "lighter",
    background: "none",
    color: theme.palette.white.main,
    "&:hover:not($active)": {
      color: theme.palette.white.main
    }
  },
  strong: {
    color: theme.palette.black.main
  },
  paper: {
    margin: 0,
    borderRadius: 0,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2
  },
  columnOne: {
    display: "flex",
    height: "inherit",
    justifyContent: "center",
    alignItems: "center"
  },
  columnTwo: {
    display: "flex",
    height: "inherit",
    justifyContent: "center",
    alignItems: "center"
  },
});

Navigation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Navigation);