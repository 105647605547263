import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Flowers from "../../images/flowerDivider/flowers.png";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Card";

const FlowersDivider = (props) => {
  const { classes } = props;

  return (
    <Paper className={classes.paper} raised={true}>
      <div className={classes.flowers}>
      </div>
    </Paper>
  )
};

const styles = (theme) => ({
  flowers: {
    backgroundImage: `url(${Flowers})`,
    backgroundRepeat: "repeat-x",
    width: "inherit",
    height: "60px"
  },
  paper: {
    marginRight: 0
  }
});

FlowersDivider.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FlowersDivider);