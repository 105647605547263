import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

const StyledMenu = (props) => {
  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  );
};

const styles = (theme) => ({
  paper: {
    border: '1px solid ' + theme.palette.black.main,
    backgroundColor: theme.palette.background.main
  }
});

export default withStyles(styles)(StyledMenu);