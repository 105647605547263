import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from "../../components/Title";
import ContactForm from "../../components/ContactForm";
import ImageLink from "../../components/ImageLink";
import GitHub from "../../images/contact/GitHub-Mark-120px-plus.png";
import LinkedIn from "../../images/contact/LI-In-Bug.png";
import Facebook from "../../images/contact/f_logo_RGB-Blue_250.png";
import HackerRank from "../../images/contact/HackerRank_logo.png";
import SoloLearn from "../../images/contact/sololearn-logo.png";
import PropTypes from "prop-types";
import SingleLineGridList from "../../components/SingleLineGridList";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    }
  };

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth});
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateWindowWidth);
    document.title = "Sydney Blackburn | Contact";
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  };

  render () {
    const { classes } = this.props;
    const { windowWidth } = this.state;
    const tileData = [
      <ImageLink
        link="https://github.com/SydneyRaeBlackburn"
        src={ GitHub }
        alt="Github Logo"
        title="Github"
        noMargin={true}
      />,
      <ImageLink
        link="https://www.sololearn.com/Profile/8251651"
        src={ SoloLearn }
        alt="SoloLearn Logo"
        title="SoloLearn"
        noMargin={true}
      />,
      <ImageLink
        link="https://www.facebook.com/SydneyyyRae10"
        src={ Facebook }
        alt="Facebook Logo"
        title="Facebook"
        noMargin={true}
      />,
      <ImageLink
        link="https://www.hackerrank.com/sydneyblackburn1?hr_r=1"
        src={ HackerRank }
        alt="HackerRank Logo"
        title="HackerRank"
        noMargin={true}
      />,
      <ImageLink
        link="https://www.linkedin.com/in/sydneyraeblackburn/"
        src={ LinkedIn }
        alt="LinkedIn Logo"
        title="LinkedIn"
        noMargin={true}
      />
    ];

    return (
      <div
        className={
          windowWidth <= 450
            ? classes.smallContainer
            : classes.container
        }
        style={{"minHeight": window.innerHeight}}
      >
        <Title title="Contact"/>
        <div className={ classes.row }>
          <div
            className={
              windowWidth <= 450
                ? classes.smallColumnOne
                : classes.columnOne
            }
          >
            <Typography variant="h3" component="h3">
              Connect with me!
            </Typography>
            <div className={classes.imgContainer}>
              {windowWidth <= 450
                ? <SingleLineGridList
                    tileData={tileData}
                  />
                : tileData.map((data, index) =>
                  <div
                    className={classes.data}
                    key={index}
                  >
                    {data}
                  </div>
                )
              }
            </div>
          </div>
          <div className={
            windowWidth <= 450
              ? classes.smallColumnTwo
              : classes.columnTwo
          }
          >
            <Typography variant="h3" component="h3">
              Send me an Email!
            </Typography>
            <Typography variant="body1" component="p">
              Note: This will open your computer's Mail
            </Typography>
            <ContactForm/>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    margin: "75px 50px 20px 50px",
    height: "100%"
  },
  smallContainer: {
    margin: "75px 10px 20px 10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  columnOne: {
    flexBasis: "49%",
    marginRight: "5px"
  },
  smallColumnOne: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  columnTwo: {
    flexBasis: "49%",
    marginLeft: "5px"
  },
  smallColumnTwo: {
    width: "100%"
  },
  imgContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  data: {
    margin: "0 25px 25px 0px"
  }
});

Contact.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Contact);