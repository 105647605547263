import React from "react";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import PropTypes from "prop-types";

const ExpansionPanels = (props) => {
  const { classes, title, blocks } = props;
  return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3" component="h3">
            {title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {blocks.map((block, index) => (
            <div className={classes.categoryBlock} key={`${title}-${index}`}>
              {block.title && block.title.map((blockTitle, idx) => (
                <Typography variant="h4" component="h4" key={`${blockTitle}-${idx}`}>
                  {blockTitle}
                </Typography>
              ))}
              {block.date && block.date.map((blockDate, idx) => (
                <Typography variant="body1" component="p" key={`${blockDate}-${idx}`}>
                  {blockDate}
                </Typography>
              ))}
              {block.info && (
                <Typography variant="body2" component="div">
                  {block.info}
                </Typography>
              )}
            </div>
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
  );
};

const styles = (theme) => ({
  categoryBlock: {
    marginBottom: "20px",
    borderLeft: "2px solid " + theme.palette.primary.main,
    paddingLeft: "10px"
  }
});

ExpansionPanels.propTypes = {
  blocks: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(ExpansionPanels);