import React from "react";
import {Route, Switch, HashRouter} from "react-router-dom";
import Navigation from "../Navigation/";
import Home from "../../views/Home/";
// import Blog from "../../views/Blog/";
// import Projects from "../../views/Projects/";
import Resume from "../../views/Resume/";
import Contact from "../../views/Contact/";
// import ExamplePostWithImages from "../../views/Blog/ExamplePostWithImages/";
// import ExamplePostWithoutImages from "../../views/Blog/ExamplePostWithoutImages/";
// import ExampleProject from "../../views/Projects/ExampleProject/";
// import UsingReactToBuildMyWebsite from "../../views/Blog/UsingReactToBuildMyWebsite/";
// import QuickAndEasySmoothie from "../../views/Blog/QuickAndEasySmoothie/";
// import ConcentrationProject from "../../views/Projects/ConcentrationProject/";
import Footer from "../Footer/";
// import Concentration from "../Concentration/";

const Router = () => (
  <HashRouter basename={process.env.PUBLIC_URL}>
    <div>
      <Navigation />
      <Switch>
        <Route exact path={process.env.PUBLIC_URL + "/"} component={Home} />
        {/* <Route exact path={process.env.PUBLIC_URL + "/Blog"} component={Blog} /> */}
        {/* <Route path={process.env.PUBLIC_URL + "/Blog/UsingReactToBuildMyWebsite"} component={UsingReactToBuildMyWebsite} />
        <Route path={process.env.PUBLIC_URL + "/Blog/QuickAndEasySmoothie"} component={QuickAndEasySmoothie} />
        <Route path={process.env.PUBLIC_URL + "/Blog/ExamplePostWithImages"} component={ExamplePostWithImages} />
        <Route path={process.env.PUBLIC_URL + "/Blog/ExamplePostWithoutImages"} component={ExamplePostWithoutImages} /> */}
        {/* <Route exact path={process.env.PUBLIC_URL + "/Projects"} component={Projects} />
        <Route path={process.env.PUBLIC_URL + "/Projects/Concentration"} component={ConcentrationProject} />
        <Route path={process.env.PUBLIC_URL + "/Projects/ConcentrationGame"} component={Concentration} /> */}
        {/* <Route path={process.env.PUBLIC_URL + "/Projects/ExampleProject"} component={ExampleProject} /> */}
        <Route exact path={process.env.PUBLIC_URL + "/Resume"} component={Resume} />
        <Route exact path={process.env.PUBLIC_URL + "/Contact"} component={Contact} />
      </Switch>
      <Footer />
    </div>
  </HashRouter>
);

export default Router;