import PropTypes from 'prop-types'
import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import StyledMenu from "../StyledMenu/";
import MenuIcon from '@material-ui/icons/Menu';
import {NavLink} from "react-router-dom";

class Hamburger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    }
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({ anchorEl: null});
  };

  render () {
    const { classes } = this.props;
    const {anchorEl } = this.state;

    return (
      <div>
        <Button
          className={classes.button}
          onClick={this.handleClick}
        >
          <MenuIcon className={classes.menuIcon}/>
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={anchorEl !== null}
          onClose={this.handleClose}
        >
          {/* <div className={classes.item}>
            <NavLink
              to={'/Blog'}
              activeClassName={classes.isActive}
              className={classes.navLink}
              onClick={this.handleClose}
            >
              Blog
            </NavLink>
          </div>
          <div className={classes.item}>
            <NavLink
              to={'/Projects'}
              activeClassName={classes.isActive}
              className={classes.navLink}
              onClick={this.handleClose}
            >
              Projects
            </NavLink>
          </div> */}
          <div className={classes.item}>
            <NavLink
              to={'/Resume'}
              activeClassName={classes.isActive}
              className={classes.navLink}
              onClick={this.handleClose}
            >
              Resume
            </NavLink>
          </div>
          <div className={classes.item}>
            <NavLink
              to={'/Contact'}
              activeClassName={classes.isActive}
              className={classes.navLink}
              onClick={this.handleClose}
            >
              Contact
            </NavLink>
          </div>
        </StyledMenu>
      </div>
    );
  }
}

const styles = (theme) => ({
  menu: {
    width: "35px",
    height: "5px",
    backgroundColor: theme.palette.black.main
  },
  menuIcon: {
    color: theme.palette.black.main
  },
  item: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemText-primary': {
        color: theme.palette.background.main,
      },
    },
    padding: "10px 25px",
    textAlign: "center",
  },
  navLink: {
    textDecoration: "none",
    height: "inherit",
    color: theme.palette.white.main,
    width: "auto",
    transition: "0.3s",
    "&:hover:not($active)": {
      color: theme.palette.primary.main
    }
  },
  isActive: {
    color: theme.palette.primary.main
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
    width: "auto",
    marginTop: 0
  }
});

Hamburger.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Hamburger);