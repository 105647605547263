import React from 'react';
import Router from "./components/RouterComponent/Router.view";

const App = () => {
  return (
    <div>
      <Router />
    </div>
  )
};

export default App;