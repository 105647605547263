import React from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import FlowersDivider from "../FlowerDivider/";

const Title = (props) => {
  const { title, classes, post } = props;
  return (
    <div>
      <Typography
        variant="h1"
        component="h1"
        className={
          post
            ? classes.postTitle
          : classes.title
        }
      >
        { title }
      </Typography>
      <FlowersDivider/>
    </div>
  )
};

const styles = () => ({
  title: {
    textAlign: "left"
  },
  postTitle: {
    textAlign: "left"
  }
});

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  post: PropTypes.bool,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(Title);